// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
    background-color: $gris-fonce;
    padding: #{(120 / 2) + px};
    margin-top: 10px;
    text-align: center;
    color: $brun-mi-moyen;
    font-size: #{(30 / 2) + px};

    span {
        color: #fff;
    }

    h1 {
        font-family: 'Roboto'; 
        font-size: #{(60 / 2) + px};
        font-weight: 300;
        color: #fff;
    }

    a {
        color: $brun-mi-moyen;

        &:focus,
        &:active,
        &:hover {
            color: $brun-mi-moyen;
            text-decoration: none;
            outline: none;
        }
    }
}

.fog-heure-ouverture {
    span {
        color: $brun-mi-moyen;
        border-bottom: 1px solid $brun-mi-moyen;
    }
}

.fog-bouton-fb-conteneur {
    // au piff 
    margin-top: 42px;
}

.fog-bouton-fb {
    font-weight: 300;
    border: 1px solid #fff;
    padding: #{(20 / 2) + px};
    // pour espacer l'icone
    padding-left: #{20 + (29 / 2) + px};
    text-align: center;
    width: 100%;
    color: #fff;

    background-image: url(../img/fb.gif);
    background-repeat: no-repeat;
    background-size: #{(29 / 2) + px};
    background-position: #{(20 / 2) + px} 50%;

    &:focus,
    &:active,
    &:hover {
        color: #fff;
        outline: none;
    }
}

    // -----------------------------------------------------------------------------
    // responsive
    // -----------------------------------------------------------------------------

    @media (max-width:375px) {

        .fog-bouton-fb {
            font-size: 13px;
        }

    }