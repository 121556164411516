// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

$fog-section-bienvenu-img-width-mobile: 50%;
$fog-section-bienvenu-img-width: 30%;

.fog-section-bienvenu {

    min-height: #{(888 / 2) + px};
    padding: #{(176 / 2) + px} 0;
    box-shadow: 0 2px 2px rgba(0,0,0,0.10);
    position: relative;
    overflow: hidden;

}

.fog-section-bienvenu-corps {
    // background-image: url(../img/roches.jpg);
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: 80% center;

    // display: flex;
    // justify-content: space-between;

    position: relative;

    &::after {
        content: "";
        width: $fog-section-bienvenu-img-width;
        min-height: 388px;

        top: 50%;
        margin-top: - #{(388 / 2) + px};

        background-image: url(../img/roches.jpg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 80% center;
        position: absolute;
        right: $gabarit-padding;
    
        z-index: 1;
    }

}

.fog-section-bienvenu-contenu {

    position: relative;
    z-index: 2;

    p {
        font-size: 15px;
        font-weight: 300;
        margin: 30px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
        
    }
    
    h1 {
        font-size: 30px;
        font-family: Playfair Display;
        font-weight: 400;
        margin: 0;
        line-height: 1;
        text-align: left;
    }

    cite {
        font-weight: 400;
        font-style: normal;
    }

}

    // -----------------------------------------------------------------------------
    // responsive
    // -----------------------------------------------------------------------------

    @media (min-width: 769px) {

        .fog-section-bienvenu-contenu {

            border-left: #{(55 / 2) + px} solid $gris-leger;
            padding-left: #{(80 / 2) + px};
            max-width: #{(1374 / 2) + px};
    
        }
    }
    
    @media (max-width: 768px) {

        .fog-section-bienvenu {
            padding: #{(92 / 2) + px} 0;        
        }
        
        .fog-section-bienvenu-contenu {
            
            text-align: center;

            h1 {
                display: none;
            }

            &::after {
       
                opacity: 0.5;
            }

        }

        .fog-section-bienvenu-corps {
            
            &::after {
                opacity: 0.2;
                right: 50%;
                width: $fog-section-bienvenu-img-width-mobile;
                margin-right: - $fog-section-bienvenu-img-width-mobile / 2;

                top: 0;
                margin-top: 0;
            }

        }

    }

// -----------------------------------------------------------------------------
// Soins et services
// -----------------------------------------------------------------------------

.fog-section-soins {
    background-color: $brun-tres-leger;
    // padding: #{(50 / 2) + px} $gabarit-padding #{(180 / 2) + px};
    // padding-top: #{(50 / 2) + px};
    // padding-bottom: #{(180 / 2) + px};
    padding:#{(120 / 2) + px} 0;

    h1 {
        position: relative;
        font-size: 30px;
        font-family: Playfair Display;
        font-weight: 400;
        margin: 0;
        margin-bottom: #{(116 / 2) + px};

    }

    .fog-boite-principale {
    
        // + vert
        &:after {
            content: "+";
            font-weight: 100;
            position: absolute;
            bottom: 7px; // magic number
            right: $fog-boite-principale-plr;
            font-size: #{(72 / 2) + px};
            color: $vert;
            line-height: 1;
        }

        // + vert
        &:hover {
            text-decoration: none;
        }
    }

}

.fog-carrousel-soins-corps {
    a {
        text-decoration: none!important;
        min-height: 525px;
        &:focus {
            outline: none;
        }
    }
}

    // -----------------------------------------------------------------------------
    // responsive
    // -----------------------------------------------------------------------------

    @media (min-width: 1400px) {

        .fog-carrousel-soins-corps {
            margin: 0 auto;
            width: 1179px;
        }

        .fog-section-soins {
            // padding: #{(50 / 2) + px} #{(260 / 2) + px} #{(180 / 2) + px};
            padding-left: 16px;
            padding-right: 16px;
        }

    }

// -----------------------------------------------------------------------------
// proprio
// -----------------------------------------------------------------------------

.fog-section-proprietaires {
  
    .fog-section-corps {
        background-image: url(../img/feuilles.jpg);
        background-repeat: no-repeat;
        background-size: #{(428 / 2) + px};
        background-position: 50% #{(40 / 2) + px};

        max-width: 1138px;
    }

}

.fog-section-proprietaires-titre-certification {
    font-size: #{(32 / 2) + px};
    font-weight: 400;
    margin-top: #{(78 / 2) + px};
}

.fog-section-proprietaires-texte-certification {
    font-size: #{(30 / 2) + px};
    font-weight: 300;
    margin: 0;
}

    // -----------------------------------------------------------------------------
    // responsive
    // -----------------------------------------------------------------------------

    @media (max-width: 960px) {

        .fog-section-proprietaires {

            .fog-section-corps {
                background: none;
            }

        }

    }

// -----------------------------------------------------------------------------
// partenaires
// -----------------------------------------------------------------------------

.fog-section-partenaires {
    padding: #{(120 / 2) + px} $gabarit-padding;
    background-color: $gris-leger;
}

.fog-section-partenaires-corps {
   
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: #{(84 / 2) + px} auto 0;

    @media (min-width: 1440px) {
 
        max-width: 70%;

    }

    @media (max-width: 960px) {
 
        flex-direction: column;
        max-width: none;
        img {
            margin: #{(68 / 4) + px} 0;
            max-width: none;
        }
 
    }
    
}

.fog-section-partenaires-bio {
    width: #{(250 / 2) + px};
}

.fog-section-partenaires-mesoestetic,
.fog-section-partenaires-clayton {
    width: #{(408 / 2) + px};
}

.fog-section-partenaires-esthederm {
    width: #{(262 / 2) + px};
}

.fog-section-partenaires-mary {
    width: #{(171 / 2) + px};
}


// -----------------------------------------------------------------------------
// carrousel photo
// -----------------------------------------------------------------------------

.fog-carrousel-photos {
    padding-bottom: 10px;
}

.fog-carrousel-photo-elem {
    background-color: pink;
    text-align: center;
    width: 295px;
    height: 210px;
    margin: 0 5px;
    overflow: hidden;

    img {
        width: 100%;
    }

    &[data-featherlight] {
        cursor: pointer;
    }
}

.slick-arrow {
    
    outline: none;
    border: 0;

    color: $bleu;
    padding: 0;
    margin: 0;
    text-align: center;
    background-color: rgba(255,255,255,0.6);
    background-repeat: no-repeat;
    background-position:50%;
    
    // text-indent: -999999999px;

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    width: 32px;

    transition: width ease-in-out 0.3s;

    &:hover {
        width: 48px;
        cursor: pointer;
    }
}

.slick-prev {
    left: 0;
    background-image: url(../img/chevron-gauche.svg);
} 

.slick-next {
    right: 0;
    background-image: url(../img/chevron-droit.svg);
} 