/**
 * Basic typography style for copy text
 */

html {
    font-size: 10px;
}

body {
    color: $text-color;
    font: 300 150% / 1.2 $text-font-stack;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Playfair Display;
    font-weight: 400;
    margin: 0;
}

h1 {
    font-family: Playfair Display; 
    font-size: #{(60 / 2) + px};
    font-weight: 300;
    text-align: center;
}

h2 {
    font-family: Playfair Display; 
    font-size: #{(52 / 2) + px};
    font-weight: 400;
}

h3 {
    font-family: Playfair Display; 
    font-size: #{(44 / 2) + px};
    font-weight: 400;
}

ul {
    padding-left: 0;
    list-style-position: inside;

    li {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
    }
}

p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
}

.fog-libelle {
    font-weight: 400;
    display: block;
    margin-bottom: 2px;
    font-size: 15px;

    & + p {
        margin-top: 4px;
    }
}

.fog-liste {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;

    li {

        display: grid;
        grid-template-columns: 20px auto;
        align-items: baseline;

        &::before {
            content: '';
            display: inline-flex;
            height: 9px;
            width: 9px;
            transform: rotate(45deg);
            background-color: $vert;
        }
    }

    &.--simple {
        li {
            grid-template-columns: 10px auto;

            &::before {
                content: '';
                display: inline-flex;
                height: 3px;
                width: 3px;
                top: -3px;
                position: relative;
                border-radius: 100%;
                background-color: #000;
            }
        }
    }
}

strong {
    font-weight: 400;
}

.fog-spacing-top {
    margin-top: 16px;
}

.fog-spacing-top--l {
    margin-top: 32px;
}