// -----------------------------------------------------------------------------
// sections communes
// -----------------------------------------------------------------------------

.fog-section {
    // padding: #{(120 / 2) + px};
    padding:#{(120 / 2) + px} $gabarit-padding;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-family: Playfair Display; 
        font-size: #{(60 / 2) + px};
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
    }

    .fog-table {
              
        tr:nth-child(even) {
            background-color:rgba($gris-leger, 0.5);
        }

    }
}


.fog-section-corps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.fog-section-entete {
    margin-bottom: #{(120 / 2) + px};
}

.fog-section-soustitre {
    font-size: #{(32 / 2) + px};
    margin-bottom: 0;
    text-align: center;
    color: #666;
}

.fog-section-contenu {
    flex: 1;
}

.fog-section-texte {
    font-size: 15px;
}

    // -----------------------------------------------------------------------------
    // responsive
    // -----------------------------------------------------------------------------

    @media (max-width: 960px) {

        .fog-section:not(.fog-section-epilation):not(.fog-section-services) .fog-section-corps {
           flex-direction: column;
        }
        
    }


// -----------------------------------------------------------------------------
// sections avec bandes
// -----------------------------------------------------------------------------

$fog-section-avec-bande-img-width-mobile: 50%;
$fog-section-avec-bande-img-width: 30%;

@media (min-width: 961px) {

    .fog-section-avec-bande {

        .fog-section-corps {

            border-left: #{(55 / 2) + px} solid $gris-leger;
            padding-left: #{(80 / 2) + px};
        
        }

    }

}

// -----------------------------------------------------------------------------
// sections fond de couleur fonce
// -----------------------------------------------------------------------------

.fog-section-fonce {
    background-color: $gris-leger;

    .fog-flex-table {
        p {
        
            &:nth-child(odd) {
                background-color: rgba(#fff, 0.5);
            }

        }
    }
    
    .fog-table {
     
        tr:nth-child(even) {
            background-color: rgba(#fff, 0.5);
        }
   
    }
}

// -----------------------------------------------------------------------------
// sections avec deux colonnes
// -----------------------------------------------------------------------------

.fog-section-avec-colonnes {
    .fog-section-corps {
        
        @media (min-width: 961px) {
            align-items: stretch;
        }

        @media (max-width: 960px) {
            align-items: stretch;
        }

        > div {
            flex: 1;

            &:first-child {
            
                @media (max-width: 960px) {
                    margin-bottom: 32px;    
                }

                @media (min-width: 961px) {
                    margin-right: 32px;    
                }

            }
            
            &:last-child {                

                @media (min-width: 961px) {
                    margin-left: 32px;    
                }

                @media (max-width: 960px) {
                    margin-top: 32px;
                }

            }
        }
    }
}

// -----------------------------------------------------------------------------
// sections avec image de fond
// -----------------------------------------------------------------------------

.fog-section-avec-bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    h1, .fog-section-soustitre {
        color: #fff;
    }

    .fog-section-corps {
        max-width: 1184px;
        flex-wrap: wrap;

        align-items: stretch;

        @media (min-width: 960px) {
            justify-content: center;
        }

        .fog-boite-principale {
            
            margin-top: 16px;
            margin-bottom: 16px;
            padding-top: 30px;

            @media (min-width: 960px) {
                flex:0 1 360px;
            }

            @media (max-width: 960px) {
                flex: 1;
                width: auto;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }


    }
}