
.fog-flex-table {
    
    margin-top: 10px;

    p {
        margin: 0;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        
    }
}

.fog-table {
    width: 100%;

    th {
        padding: 8px;
        font-weight: 400;

        &:first-child {            
            text-align: left;
        }
    }

    td {
        padding: 8px;
        
        &:not(:first-child) {
            text-align: center;
        }
    }

}