// -----------------------------------------------------------------------------
// styles spécifiques à la page maquillage
// -----------------------------------------------------------------------------

.fog-section-epilation {

    .fog-section-corps {
        
        max-width: 1184px;
        align-items: stretch;

        @media (max-width: 960px) {
            align-items: center;
        }

        @media (max-width: 1090px) {
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }

    .fog-boite-principale {
        border: 0;
        box-shadow: none;
    }

    .fog-boite-corps {
        padding-left: 0;
        padding-right: 0;
    }

}

.fog-section-epilation-couts {
    .fog-section-corps {
        max-width: 1184px;
        
        > div {
        
            &:first-child {
            
                h2 {
                    background-color: #fff;
                    padding: 10px;
                    margin-bottom: 52px;
                }
                
                h3 {
                    font-size: #{(52 / 2) + px};
                    margin-bottom: 25px;

                }
                
                h4 {
                    font-size: #{(32 / 2) + px};
                }


                @media (min-width: 961px) {
                    background-image: url(../img/fond-epilation.png);               
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position:0 bottom;
                }


                
                
            }
            
            &:last-child {
                h2 {
                    margin-bottom: 20px;
                }
            }
        }
    }
    
}
