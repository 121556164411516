// -----------------------------------------------------------------------------
// boite principale
// -----------------------------------------------------------------------------

$fog-boite-principale-ptb: #{(50  / 2) + px};
$fog-boite-principale-plr: #{(40  / 2) + px};
$fog-boite-principale-width: 526px;

:root {
    --fog-boite-border-color: #cecbc5;
}

.fog-boite-principale {
    width: $fog-boite-principale-width / 2;
    display: block;
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0,0,0,0.10);
    border: 1px solid var(--fog-boite-border-color);
    position: relative;
    color: $gris-fonce;
    
    @media (min-width: 961px) {
        margin:0 #{(32  / 2) + px};
    }

    img {
        max-width: 100%;
    }

    h2 {
        font-size: #{(52 / 2) + px};
        font-family: Playfair Display;
        font-weight: 400;
        margin: 0;
        line-height: 1;
        margin-bottom: #{(46 / 2) + px};

        // ligne verte
        &::after {
            content: "";
            display: block;
            width: #{(60 / 2) + px};
            height: #{(10 / 2) + px};
            background-color: $vert;
            margin-top: #{(34 / 2) + px};
        }
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 24px;
        }

        margin: 0 #{(16 / 2) + px};
    }

    .fog-flex-table {
        p {
        
            &:nth-child(odd) {
                background-color: rgba($gris-leger, 0.5);
            }

        }
    }    
}

.fog-boite-corps {
    display: block;
    padding: $fog-boite-principale-ptb $fog-boite-principale-plr;
    font-size: #{(30 / 2) + px};
    font-weight: 300;
    position: relative; 
}

// -----------------------------------------------------------------------------
// contour
// -----------------------------------------------------------------------------

.fog-boite--sans-contour {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    
    .fog-boite-corps {
        padding-left: 0;
        padding-right: 0;
    }
}

.fog-boite--contour-vert {
    --fog-boite-border-color: #{$vert};
}

// -----------------------------------------------------------------------------
// width
// -----------------------------------------------------------------------------

.fog-boite--wide {
    width: auto;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
}

// -----------------------------------------------------------------------------
// boite circulaire
// -----------------------------------------------------------------------------

.fog-boite-circulaire {
    width: 50%;
    text-align: center;

    &:first-child {
        margin-right: 10%;
    }
    
    &:last-child {
        margin-left: 10%;
    }

    h2 {
        font-family: Playfair Display; 
        font-size: #{(52 / 2) + px};
        font-weight: 400;
        
    }
}

.fog-boite-circulaire-image {
    border-radius: 50%;
    overflow: hidden;
    max-width: #{(480 / 2) + px};
    max-height: #{(480 / 2) + px};
    margin: 0 auto #{(32 / 2) + px};

    img {
        width: 100%;
    }
}

.fog-boite-circulaire-soustitre {
    font-size: #{(36 / 2) + px};
    font-weight: 400;
    color: $vert;
    margin: #{(24 / 2) + px} 0 0 0;
}

.fog-boite-circulaire-email {
    font-size: #{(30 / 2) + px};
    color: $bleu;
    margin: 0;

    &:hover {
        color: $bleu;
    }
}

    // -----------------------------------------------------------------------------
    // responsive
    // -----------------------------------------------------------------------------

    @media (max-width: 960px) {

        .fog-boite-circulaire.fog-boite-circulaire {
            margin: 0;
            width: 100%;
        }

        .fog-boite-circulaire + .fog-boite-circulaire {
            margin-top: #{(94 / 2) + px};
        }

    }




.fog-flex-boite {
    
    background-color: #f4f2ee;
    padding: 10px;
    margin-top: 20px; 

    p {
        
        background-color: #fff;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    
    }
}

.fog-boite-double {
    
    border:10px solid $gris-leger;
    background-color: #fff;
    padding: 10px;
    
    text-align: center;
    position: relative;

    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 960px) {
        padding-top: 55px;
    }

}

.fog-sticker {
    position: absolute;
    top: 0;
    right: 0;

    background-color: $vert;
    padding: 5px 15px;
    text-align: center;
    color: #fff;
    font-size: 17px;
}

.fog-flex-wrapper {
    display: flex;

    &-item + &-item {
        margin-left: 32px;
    }
}