// -----------------------------------------------------------------------------
// styles spécifiques au composant pastille
// -----------------------------------------------------------------------------


%fog-pastille {
    width: 15px;
    height: 15px;
    display: block;
    margin: 0 auto 20px;
}

.fog-pastille-verte {
    @extend %fog-pastille;
    background-color: $vert;
}

.fog-pastille-orange {
    @extend %fog-pastille;
    border-radius: 50%;
    background-color: $orange;
}

.fog-pastille-jaune {
    @extend %fog-pastille;
    overflow: visible;
    transform: rotateZ(-45deg);
    transform-origin: 0 100% 0deg;
    background-color: $jaune;
}

// -----------------------------------------------------------------------------
// Dans une boite
// -----------------------------------------------------------------------------

.fog-boite-principale {
    
    [class*=fog-pastille] {
        position: absolute;
        top: 17px;
        right: 15px;
    }

}