// -----------------------------------------------------------------------------
// styles spécifiques à la page maquillage
// -----------------------------------------------------------------------------

.fog-section-soins-base {

    .fog-section-corps {
        // align-items: flex-start;
        max-width: 1138px;
    }

    h2 {
        // ligne verte
        &::after {
            content: "";
            display: block;
            width: #{(60 / 2) + px};
            height: #{(10 / 2) + px};
            background-color: $vert;
            margin-top: #{(34 / 2) + px};
        }
    }

    h3 {
        font-family: $text-font-stack;
    }

    .fog-boite-double {

        @media (min-width: 961px) {
            width: 300px;
            height: 300px;
            float: right;
        }

        h2 {
            font-size: 24px;
            &::after {
                display: none;
            }
        }
    }

}

.fog-section-soins-haut-de-gamme {
    background-image: url(../img/soinshautdegamme-kraken.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    h1, .fog-section-soustitre {
        color: #fff;
    }

    .fog-section-entete {
        text-shadow: 1px 1px 2px black;
    }

    .fog-section-corps {
        max-width: 1184px;
        flex-wrap: wrap;

        align-items: stretch;

        @media (min-width: 960px) {
            justify-content: center;
        }

        .fog-boite-principale {
            
            margin-top: 16px;
            margin-bottom: 16px;
            padding-top: 30px;

            @media (min-width: 960px) {
                flex:0 1 360px;
            }

            @media (max-width: 960px) {
                flex: 1;
                width: auto;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }


    }
}

.fog-section-techno {
    
    .fog-section-corps {
        max-width: 1138px;
    }
    
    h2 + p {
        margin-top: 4px;
    }

    .fog-flex-table + h2 {
        margin-top: 40px;
    }
}
