// -----------------------------------------------------------------------------
// bandeaux communs
// -----------------------------------------------------------------------------

.fog-bandeau {
    background: #8e8276;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: relative;
    z-index: 3;
}

.fog-bandeau-corps {
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fog-bandeau-contenu {
    width: #{(840 / 2) + px};
    text-align: center;
}

.fog-bandeau-titre-conteneur {
    margin: 0;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: 30px;

    // lignes blanches
    &::after,
    &::before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: calc(50% - #{$fog-fioriture-dimension*2} );
        background-color: #fff;
        bottom: 0;
    }
    &::after {
        right: 0;
    }
    
    // contenu texte
    > span {
        font-family: Playfair Display;
        font-weight: 300;
        line-height: 1;
    }
}

.fog-bandeau-titre-2,
.fog-bandeau-titre-3 {
    font-size: 43px;
    text-transform: uppercase;
    line-height: 1;
}

.fog-bandeau-titre {
    
    margin-bottom: 30px;
    font-size: 22px;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // lignes blanches
    &::after,
    &::before {
        content: "";
        // display: block;
        // position: absolute;
        height: 1px;
        width: calc(50% - 80px );
        background-color: #fff;
        top: 0;
    }
}

.fog-bandeau-titre-decoration {
        
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -5px;
        margin-bottom: -4px;
        height: $fog-fioriture-dimension;
        width: $fog-fioriture-dimension;
        background-color: #fff;
        bottom: 0;
        transform: rotate(45deg);
    
}

.fog-bouton {
    display: block;
    background-color: $vert;
    padding: #{(36 / 2) + px}; 

    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    margin-top: #{(70  / 2) + px};
    border-radius: 3px;
    transition: background-color 0.5s ease;

    &:focus,
    &:hover {
        color: #fff;
        text-decoration: none;
        background-color: darken($vert, 3%);
    }
}

// -----------------------------------------------------------------------------
// bandeau forfait
// -----------------------------------------------------------------------------

.fog-bandeau-forfait {
    
    height: #{(790 / 2) + px};
    background-image: url(../img/bandeau-forfait.jpg);
    
}

// -----------------------------------------------------------------------------
// bandeau massage
// -----------------------------------------------------------------------------

.fog-bandeau-masso {
    
    height: #{(790 / 2) + px};
    background-image: url(../img/bandeau-massage.jpg);
    @media (max-width: 434px) {
        background-position: 70% 0;
    }  
}

// -----------------------------------------------------------------------------
// bandeau maquillage
// -----------------------------------------------------------------------------

.fog-bandeau-maquillage {
    
    height: #{(790 / 2) + px};
    background-image: url(../img/bandeau-maquillage.jpg);

    @media (max-width: 434px) {
        background-position: 0;
    }
}

.fog-bandeau-maquillage-permanent {
    
    height: #{(790 / 2) + px};
    background-image: url(../img/bandeau-maquillage-permanent.jpg);
    background-position: 0;

    .fog-bandeau-contenu {
        width: auto;
    }
}

// -----------------------------------------------------------------------------
// bandeau soin du corps
// -----------------------------------------------------------------------------

.fog-bandeau-soins-du-corps {
    
    height: #{(790 / 2) + px};
    background-image: url(../img/bandeau-soinsducorps.jpg);
    @media (max-width: 434px) {
        background-position: -500px 0;
    }  
}

// -----------------------------------------------------------------------------
// bandeau epilation
// -----------------------------------------------------------------------------

.fog-bandeau-epilation {
    
    height: #{(790 / 2) + px};
    background-image: url(../img/bandeau-epilation.jpg);
    
    @media (max-width: 425px) {
        background-position: -190px 0;
    }   
}

// -----------------------------------------------------------------------------
// bandeau soin du visage
// -----------------------------------------------------------------------------

.fog-bandeau-soins-du-visage {
    
    height: #{(790 / 2) + px};
    background-image: url(../img/bandeau-soins-visage.jpg);
    background-position: 0 0;

    @media (max-width: 768px) {
        background-position: -190px 0;
    }        
}

// -----------------------------------------------------------------------------
// bandeau nous joindre
// -----------------------------------------------------------------------------

.fog-bandeau-nousjoindre {
    
    height: #{(790 / 2) + px};
    position: relative;
    
    #gmap {
        height: #{(790 / 2) + px};
    }
}

// -----------------------------------------------------------------------------
// bandeau accueil
// -----------------------------------------------------------------------------

.fog-bandeau-index {
    height: #{(995 / 2) + px};
    background-image: url(../img/bandeau-index.png);
    background: url(../img/bandeau-index.png), linear-gradient(to bottom,  #8e8276 0%,#685c51 100%);
    background-size: contain;
    background-position: 20% 0;
    background-repeat: no-repeat;

    .fog-bandeau-corps {
        display: block;
    }

    .fog-bandeau-contenu {
    
        margin-top: #{(220 / 2) + px};
        float: right;

    }

    .fog-bouton {
        margin-top: #{(120  / 2) + px};
    }

}

// -----------------------------------------------------------------------------
// responsive
// -----------------------------------------------------------------------------

@media (max-width: 465px) {

    .fog-bandeau-index {
        background-size: cover;
        // height: 300px;
    }

}

@media (max-width: 768px) {

    .fog-bandeau {
        
        height: 300px;

        .fog-bandeau-contenu {
            margin: 30px auto 0;
            float: none;
            width: 246px;
        }

        $fog-bandeau-bouton-largeur: 280px;
        .fog-bouton {
            position: absolute;
            bottom: -34px;
            width: $fog-bandeau-bouton-largeur;
            left: 50%;
            margin-left: -#{$fog-bandeau-bouton-largeur/2};
        }
    }

    .fog-bandeau-index {
        
        .fog-bandeau-titre {
            display: none;
        }

    }

    .fog-bouton {
        font-size: 18px;
    }

    .fog-bandeau-titre-2,
    .fog-bandeau-titre-3 {
        font-size: 25px;
        
    }

}


@media (max-width: 320px) {

    .fog-bandeau-contenu {
        width: 100%;
    }

}