// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

@media (max-width: 960px) {

    .fog-telephone-cliquable {
        display: inline;
        &:not(.fog-bouton){
            span {
                color: $brun-mi-moyen;
                border-bottom: 1px solid $brun-mi-moyen;
            }
        }
    }
    
    .fog-bouton.fog-telephone-cliquable {
        display: block;
    }

    .fog-telephone-non-cliquable {
        display: none!important;
    }

}

@media (min-width: 961px) {

    .fog-telephone-cliquable {
        display: none!important;
    }

    .fog-telephone-non-cliquable {
        display: inline;
    }
    
    .fog-bouton.fog-telephone-non-cliquable {
        display: block;
    }

}


body {
    min-width: 320px;
}

.fog-gabarit {
    max-width: $gabarit-max-width;
    padding: 0 $gabarit-padding;
    margin: 0 auto;
    width: 100%;
}

.fog-404-logo {
    height: #{(170 / 2) + px};
    width: #{(374 / 2) + px};
    background: url(../img/logo.png) 0 0 no-repeat;
    background-size: cover;
    display: block;
    margin: 0 auto 48px;
}

// -----------------------------------------------------------------------------
// responsive
// -----------------------------------------------------------------------------

@media (min-width: 1440px) {

    .fog-gabarit {
        padding: 0 $gabarit-fixe-padding;
    }

}

