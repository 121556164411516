
.header-container {
    position: relative;
}

nav {
   
    a {
        text-transform: uppercase;
        text-decoration: none;
        position: relative;
    }

}

.fog-nav {
    height: #{(240 / 2) + px};
    z-index: 5;
    position: relative;
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0,0,0,0.10);
    display: flex;

}

.fog-nav-corps {
   
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    > a {
        color: $text-color;
        // font-size: 18px;
        text-transform: uppercase;
        text-decoration: none;
        position: relative;
        &.fog-nav-active,
        &:hover {
            text-decoration: none;
            &:not(.fog-logo)::after {
                content: "";
                display: block;
                height: 3px;
                position: absolute;
                bottom: -17px;
                right: 0;
                left: 0;
                background-color: $vert;            
            }
        }
    }

}

.fog-nav-active {
    cursor: default!important;
}

.fog-logo {
    height: #{(170 / 2) + px};
    width: #{(374 / 2) + px};
    background: url(../img/logo.png) 0 0 no-repeat;
    background-size: cover;
}

$fog-nav-mobile-padding: 30px;

.fog-nav-mobile {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-x: hidden;
    overflow-y: auto;
    height: 0;

    background-color: $brun-leger;

    opacity: 0;

    z-index: 8;

    transition: height 0.5s ease, opacity 0.5s ease;



    &.fog-nav-mobile-ouvert {
        height: 100%;
        z-index: 10;
        opacity: 1;

        .fog-nav-mobile-mask {
            height: 100%;
            background-color: $brun-leger;
        }
    }

}

.fog-nav-mobile-active {
    font-weight: 400!important;
}

.fog-entete-mobile {
    display: block;
    background: transparent url(../img/logo.png) 50% 16px no-repeat;
}

.fog-nav-mobile-entete {
    background: $brun-leger url(../img/logo-blanc.png) 50% 16px no-repeat;
}

.fog-entete-mobile,
.fog-nav-mobile-entete {
    position: relative;
    z-index: 9;
    height: #{(240 / 2) + px};
    width: 100%;
    background-size: #{(374 / 2) + px} #{(170 / 2) + px};

}

.fog-nav-mobile-entete {
    position: fixed;
    z-index: 10;
}


.fog-nav-mobile-corps {

    margin-top: #{(240 / 2) + px};
    margin-bottom: 30px;

    > a:not(.fog-nav-mobile-menu) {
        display: block;
        color: #fff;
        font-size: 24px;
        font-weight: 300;

        padding: 16px 16px 16px 0;
        border-bottom: 2px solid #fff;
    }

    padding-left: $fog-nav-mobile-padding;
    padding-top: $fog-nav-mobile-padding;

}


.fog-nav-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 11;
    width: 44px;
    height: 44px;

    span {
        background-color: $vert;
        height: 5px;
        width: 32px;
        display: block;
        &:not(:last-child){
            margin-bottom: 6px;
        }
        
    }
    
    &.fog-nav-mobile-menu-ouvert { 
        
        position: fixed;

        span {
            background-color: #fff;
        }
    }

}

#fog-nav-mobile-soin-liste {
    padding: 0;
    list-style: none;

    
    border-bottom: 2px solid #fff;
    padding: $fog-nav-mobile-padding $fog-nav-mobile-padding 0 0;

    a {
        color: #fff;
        display: block;
        font-size: 15px;
        font-weight: 300;
        text-transform: none;
        padding-bottom: 30px;
        text-decoration: none;

        &::before {
            content: "-";
            margin-right: 0.4em;
        }
    }
}


// -----------------------------------------------------------------------------
// responsive
// -----------------------------------------------------------------------------

@media (min-width: 769px) {

    .fog-nav-mobile-menu,
    .fog-entete-mobile,
    .fog-nav-mobile {
        display: none;
    }

}

@media (max-width: 768px) {

    nav:not(.fog-nav-mobile) {
        display: none;
    }

    .fog-logo {
        display: none!important;
    }

}


// -----------------------------------------------------------------------------
// nav secondaire
// -----------------------------------------------------------------------------


.fog-nav-soins {
   
    display: flex;
    align-items: center;
    height: 100%;
    color: $text-color;
    text-transform: uppercase;
    cursor: pointer;

    > span:not(.fog-nav-secondaire) {
        position: relative;
    }

    &.fog-nav-active,
    &:hover {

        > span:not(.fog-nav-secondaire) {
            
            &::after {
                content: "";
                display: block;
                height: 3px;
                position: absolute;
                bottom: -17px;
                right: 0;
                left: 0;
                background-color: $vert;          
            }
        }

    }
}

$fog-nav-secondaire-height: 60px;

.fog-nav-secondaire {
    background-color: rgba(118,110,101,0.5);
    height: $fog-nav-secondaire-height;

    position: absolute;
    bottom: 0;

    width: 100%;
    display: flex;
     
    box-shadow: 0 2px 2px rgba(0,0,0,0.10);
    transition: opacity 0.4s ease-in-out, bottom 0.4s ease-in-out;
    z-index: 4;

    a {

        color: #fff;
        // font-size: 18px;
        font-weight: 400;
        position: relative;

        display: flex;
        align-items: center;
        padding: 0 20px;
        white-space: nowrap;
        transition: padding 0.45s ease;

        @media (max-width: 900px) {
            padding: 0 10px;
        }

        &:hover {
            text-decoration: none;
            &:not(.fog-logo){
                background-color: rgba(0,0,0,0.15);       
            }
        }
    }

    &.fog-nav-secondaire-ouvert {
        bottom: -$fog-nav-secondaire-height;
    }

}

.fog-nav-secondaire-corps {
    width: 100%;
    display: flex;
    justify-content: center;
}

.fog-breadcrumbs.fog-nav-secondaire {
    bottom: -$fog-nav-secondaire-height;

    .fog-breadcrumbs-active {
        background-color: rgba(0,0,0,0.25);
        &:hover {
            background-color: rgba(0,0,0,0.25);
        }
    }
}
