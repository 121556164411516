// -----------------------------------------------------------------------------
// styles spécifiques à la page maquillage
// -----------------------------------------------------------------------------

.fog-section-pourquoi {
    .fog-section-corps {
        justify-content: center;
    }

    .fog-liste + .fog-liste {
        margin-left: 75px;
        padding-left: 75px;
        border-left: 4px solid $gris-leger;
    }
}

@media (min-width: 961px) {

    .fog-section-promesse {
        .fog-section-corps {
            align-items: flex-start;
            justify-content: center;
        }
    }

}

@media (max-width: 1080px) {
    .fog-section-promesse {
        .fog-section-corps {
            flex-wrap: wrap;
        }
    }
}

@media (max-width: 960px) {

    .fog-notice {
       margin-top: 40px;
    }

}

.fog-section-promesse-notice  {
    text-align: center;
    max-width: $fog-boite-principale-width + 32px;
    width: auto;
}

.fog-section-maquillage-permanent-prix-notice  {
    text-align: center;
    
    width: auto;
    padding: 10px;
    background-color: rgba($gris-leger, 0.75);
    
    @media (max-width: 960px) {
        margin-top: 32px;
    }

    @media (min-width: 961px) {
        margin-top: 16px;
        max-width: $fog-boite-principale-width + 32px;
    }
}

.fog-section-maquillage-permanent-prix {
    background-image: url(../img/maquillage-permanent-prix.jpg); 

    .fog-section-entete {
        text-shadow: 1px 1px 2px black;
    }
}

.fog-section-expertise  {
    .fog-section-corps {
        max-width: 1034px;
    }
}
