
.fog-ornement {
    text-align: center;
}

.fog-ornement-titre {
 
    display: inline-block;
    text-align: center;
    position: relative;
    padding-bottom: 25px;

    // lignes
    &::after,
    &::before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: calc(50% - #{$fog-fioriture-dimension*2} );
        background-color: $gris-fonce;
        bottom: 0;
    }
    &::after {
        right: 0;
    }
}

.fog-ornement-puce {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    margin-bottom: -4px;
    height: $fog-fioriture-dimension;
    width: $fog-fioriture-dimension;
    background-color: $gris-fonce;
    bottom: 0;
    transform: rotate(45deg);
}