
// .featherlight {

//     &::before {
//         margin: 0!important;
//     }

//     .featherlight-content {
//         background-color: transparent;
//         padding: 0;
//         margin: 0;
//         border: 0;

//         img {
//             width: 100%;
//         }
//     }
// }



