// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Regular font family
/// @type List
$text-font-stack: 'Roboto', 'Playfair Display', 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;



$brun-tres-leger: #e3ddd3;
$brun-leger: #8e8276;
$brun-moyen: #685c51;
$brun-mi-moyen: #807468;

$gris-tres-leger: #f5f3f0;
$gris-leger: #ebe7e0;
$gris-moyen: #333;
$gris-fonce: #212123;

$vert: #bad02f;
$bleu: #0391e3;
$orange: #df946a;
$jaune: #f3c856;

$fog-fioriture-dimension: 8px;

/// Copy text color
/// @type Color
$text-color: $gris-fonce;

/// Main brand color
/// @type Color
$brand-color: $bleu;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;





/// Container's maximum width
/// @type Length
$gabarit-max-width: 1440px !default;

/// Padding générale
/// @type Length
$gabarit-fixe-padding:  #{(260 / 2) + px};

// 130px a 1440px
$gabarit-padding: 9%;




/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
  'small': (min-width: 320px),
  'medium': (min-width: 768px),
  'large': (min-width: 1024px),
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/img/' !default;
