// -----------------------------------------------------------------------------
// styles spécifiques à la page
// -----------------------------------------------------------------------------

.fog-section-soins-du-corps {
    .fog-section-corps {
        max-width: 1184px;
        
        h2 {
            margin-bottom: 15px;
        }

        > div {
        
            &:first-child {
        
                h3 {
                    font-size: #{(52 / 2) + px};
                    margin-bottom: 25px;

                }
                
                h4 {
                    font-size: #{(32 / 2) + px};
                }
            }
        
        }
    }

    .fog-flex-table {
    
        p {
        
            &:nth-child(odd) {
                background-color: rgba($gris-leger, 0.5);
            }

        }

        & + h2 {
            margin-top: 30px;
        }
    }

    .fog-flex-boite {

        p {
        
            @media (max-width: 375px) {

                flex-direction: column;

            }
        }
    }

}

// image sous le tableau
.fog-section-soins-du-corps-bio {
    width: 93px;
    
    @media (min-width: 376px) {
        margin-right: 7px;
    }
            
    @media (max-width: 375px) {
        margin-bottom: 10px;
    }

}

.fog-section-soins-avances {
    background-image: url(../img/fond-soins-avances.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    h1, .fog-section-soustitre {
        color: #fff;
    }

    .fog-section-entete {
        text-shadow: 1px 1px 2px black;
    }

    .fog-section-corps {
        max-width: 1184px;
        align-items: stretch;
        justify-content: space-around;

        div {
            flex: 1;
        }
    }

    .fog-flex-table {
        p {
            background-color:rgba($gris-leger, 0.5);

            & + p {
                margin-top: 4px;
            }
        }
    }

    .fog-boite-principale {
        border: 1px solid rgba(#000, 0.5);
        width: auto;

        &:not(:last-child){
            @media (max-width: 960px) {
                margin-bottom: 30px;
            }
        }
    }
}

.fog-bouton-transparent {
    padding: 10px 20px;
    text-align: center;
    background-color: rgba(#fff, 0.5);

    @extend h2;


    margin-top: 37px;

}