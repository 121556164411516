// -----------------------------------------------------------------------------
// certificats
// -----------------------------------------------------------------------------

.fog-section-certificats {
    height: #{(640 / 2) + px}; // changé min-height pour height, align-items bug sur IE sinon
    background-color: $brun-mi-moyen;
    background-image: url(../img/fond-certificat.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;

    color: #fff;
    font-size: #{(32 / 2) + px};
    font-weight: 300;

    display: flex;
    align-items: center;
}

.fog-section-certificats-corps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    max-width: #{(838 / 2) + px};
    margin: 0 auto;

    h1 {
        font-family: Playfair Display; 
        font-size: #{(60 / 2) + px};
        font-weight: 300;
        border: 1px solid #fff;
        padding: #{(34 / 2) + px};
        text-align: center;
        width: 100%;
        margin-bottom: #{(50 / 2) + px};

        // todo icone de cadeau

    }

    p {
        margin: 0;
    }
}

.fog-section-certificats-titre-telephone,
.fog-section-certificats-num-telephone {
    color: #fff;
    font-size: #{(40 / 2) + px};
}

.fog-section-certificats-titre-telephone {
    font-weight: 300;
    margin-top: #{(50 / 2) + px};

    a, a:visited {
        color: #fff;

        &:hover {
            color: #fff;
            text-decoration: none;
        }

        > span {
                padding-bottom: 2px;
                border-bottom: 1px solid #fff;
        }
    }
}

.fog-section-certificats-num-telephone {
    font-weight: 400;

    &:focus,
    &:active,
    &:hover {
        text-decoration: none;
        color: $vert;
    }
}

// -----------------------------------------------------------------------------
// certificats masso
// -----------------------------------------------------------------------------

.fog-section-certificats.fog-section-certificats-masso {
    
    background-image: url(../img/bandeau-forfait.jpg);

}

// -----------------------------------------------------------------------------
// certificats soins
// -----------------------------------------------------------------------------

.fog-section-certificats.fog-section-certificats-soins-corps {
    
    background-image: url(../img/bandeau-forfait.jpg);

}

.fog-section-certificats-soins-visage,
.fog-section-certificats-soins-corps {
    margin-top: 10px;
}

// -----------------------------------------------------------------------------
// responsive
// -----------------------------------------------------------------------------

@media (max-width: 768px) {

    .fog-section-certificats {
        min-height: #{(544 / 2) + px};
        padding: #{(30 / 2) + px};

        h1 {
            font-size: #{(50 / 2) + px};
        }
    }

}
