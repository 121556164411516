// -----------------------------------------------------------------------------
// styles spécifiques à la page massothérapie
// -----------------------------------------------------------------------------
.fog-section-masso {
    .fog-section-corps {
        align-items: stretch;
        max-width: 1140px;
    }
}

.fog-section-masso-tarifs {
    display: flex;
    justify-content: center;
    flex: 1;
    .fog-flex-table {
        min-width: 485px;
        @media (max-width: 768px) {
            min-width: 100%;
        }
        p {
            &:nth-child(odd) {
                background-color: rgba($gris-leger, 0.5);
            }
        }
    }
}

.fog-section-masso-forfait {
    position: relative;
    &::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 26px 90px 0 90px;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        left: 50%;
        margin-left: - #{180/2 + px};
        top: 0;
    }

    .fog-section-corps {
        flex-wrap: wrap;
        align-items: stretch;

        @media (min-width: 960px) {
            justify-content: center;
        }
    }

    .fog-boite-principale {
        width: calc( 50% - 30px); // pour IE
        margin: 15px;
        flex: 0 1 calc( 50% - 30px);
    }
}

.fog-section-masso-forfait-2 {
    padding-top: 0;
    .fog-section-corps {
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
    }
    .fog-ornement-titre {
        min-width: 40%;
        margin-top: -25px;
    }
    .fog-section-contenu {
        display: flex;
        flex-wrap: wrap;
    }
    .fog-section-entete {
        width: 100%;
    }
    .fog-boite-principale {
        width: calc( 50% - 30px);
        margin: 15px;
        flex: 0 1 calc( 50% - 30px);
        @media (max-width: 960px) {
            width: 100%;
            flex: 0 1 100%;
        }
    }
    .fog-flex-table.fog-flex-table {
        p {
            background-color: rgba($gris-leger, 0.5);
            &+p {
                margin-top: 4px;
            }
        }
    }
}

.fog-asterix {
    color: $vert;
    font-size: 70%;
    vertical-align: top;
}