// -----------------------------------------------------------------------------
// styles spécifiques à la page nous joindre
// -----------------------------------------------------------------------------



.fog-section-nousjoindre-image {
    
    width: 100%;
    
    img[src*=desktop] {
        display: none;
    }

    img[src*=mobile] {
        width: 100%;
    }

    @media (max-width: 960px) {
        margin-top: 30px;
    }

}

.fog-section-nousjoindre {
  
    .fog-section-corps {
        align-items: flex-start;

        @media (max-width: 960px) {
            flex-direction: column-reverse!important;
        }

    }

    .fog-bouton {
        margin-top: 0;
        margin-bottom: 36px;
        padding-top: 12px;
        padding-bottom: 12px;
    }


    h2 {
        margin-bottom: 20px;
        
        &::after {
            content: "";
            display: block;
            width: 30px;
            height: 5px;
            background-color: $vert;
            margin-top: 8px;

        }
    }

    h3 {
        font-size: 16px;
        margin-bottom: 2px;
        font-family: $text-font-stack;

    }

    address {
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 35px;
        font-style: normal;
    }

    p {
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    p + h3 {
        margin-top: 35px; 
    }
 

}

.fog-nousjoindre-courriel {
    color: $bleu;
    font-weight: 400;
}

.fog-section-nousjoindre-heures {
    background-color: $brun-tres-leger;
    padding: 30px;
    margin-top: 30px;

    h3 {
        font-family: $text-font-stack;
        margin-bottom: 20px;
    }
    min-width: 245px;

    p {
        display: flex;
        justify-content: space-between;
        margin: 0;
    }
}

.fog-bandeau-nousjoindre {
    position: relative;
}

    // -----------------------------------------------------------------------------
    // responsive
    // -----------------------------------------------------------------------------

    @media (min-width: 961px) {

        .fog-section-nousjoindre-image {
            height: auto;
            // width: #{(524 / 2) + px};
            flex: 0 1 #{(524 / 2) + px};
            img[src*=mobile] {
                display: none;
            }
            img[src*=desktop] {
                display: block;
                width: 100%;
            }

        }

        .fog-section-nousjoindre-contenu {

            // width: calc(100% - #{(524 / 2) + px});
            flex: 1;
            padding-left: 68px;

        }

    }

    @media (min-width: 1200px) {

  
        .fog-section-nousjoindre-contenu-corps {
            position: relative;
            padding-right: 285px;
        }

        .fog-section-nousjoindre-heures {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            bottom: 0;
        }

    }