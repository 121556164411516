@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendor/normalize',
  'vendor/featherlight';

// 3. Base stuff
@import
  'base/base',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/nav',
  'layout/section',
  'layout/footer';

// 5. Components
@import
  'components/button',
  'components/ornement',
  'components/boite',
  'components/certificats',
  'components/pastille',
  'components/notice',
  'components/tableau',
  'components/bandeau';

// 6. Page-specific styles
@import
  'pages/home',
  'pages/nousjoindre',
  'pages/forfait',
  'pages/masso',
  'pages/epilation',
  'pages/soinsduvisage',
  'pages/soinsducorps',
  'pages/maquillage',
  'pages/maquillage-permanent';
