// -----------------------------------------------------------------------------
// styles spécifiques à la page maquillage
// -----------------------------------------------------------------------------

.fog-section-maquillage {
    
    ul {
        list-style: none;
    }

    .fog-section-corps {
        align-items: flex-start;
        max-width: 1138px;
    }

    
    h1 {
        text-align: left;
        text-transform: capitalize;
    }

}

.fog-section-maqillage-tarifs {
    margin-top: 40px;

    h2 {
        font-family: Playfair Display; 
        /*font-size: #{(60 / 2) + px};*/
        font-weight: 300;
        margin-bottom: 15px;
    }

    p {
        padding: 10px;
        margin: 0;
      
        &:nth-child(even) {
            background-color: $gris-leger;
        }

        display: flex;
        justify-content: space-between;
        
    }

    & + p {
        font-size: 14px;
        margin: 8px 0 0;
    }

}

.fog-section-cours-maquillage {
    text-align: center;
}