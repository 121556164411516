// -----------------------------------------------------------------------------
// styles spécifiques au composant notice
// -----------------------------------------------------------------------------

.fog-notice {
    background-color: $gris-leger;
    position: relative;

    @media (min-width: 960px) {
            max-width: 360px;
    }
    
    @media (max-width: 960px) {
           margin-top: 40px;
    }

    h1 {
        position: relative;
        text-align: left;
        &::after {
            content: "";
            width:36px;
            height: 6px;
            background-color: $vert;
            display: block;
            margin-top: 16px;
        }

    }

    img {
        width: 100%;
        display: block;
    }
}

.fog-notice-soustitre {
    margin-top: 4px;
    color: $vert;
}

.fog-section-contenu + .fog-notice {
    margin-left: 70px;
}

.fog-notice-corps {
    padding: 30px 23px 0 30px;
}

.fog-notice-pied {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.42);
    padding: 0 30px;
    color: #fff;
    font-size: 20px;
    display: flex;
    align-items: center;
    
    p {
        margin: 0;
    }
}